import {useState, useEffect, useCallback} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ArrowRightCircle} from 'react-bootstrap-icons';
import TrackVisibility from 'react-on-screen';

import headerImg from "../assets/header-img.webp";

const Header = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const period = 2000;

  const tick = useCallback(() => {
    const toRotate = ["Software Engineer", "Full-Stack Web Developer", "JavaScript Instructor"];
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ?
      fullText.substring(0, text.length - 1) :
      fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }, [isDeleting, loopNum, text.length])

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {clearInterval(ticker)};
  }, [delta, text, tick])

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({isVisible}) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>
                    {`Hi! I'm Rafath`} <span className="txt-rotate" dataPeriod="1000" data-rotate='["Software Engineer", "Full-Stack Web Developer", "JavaScript Instructor"]'>
                      <span className="wrap">{text}</span></span></h1>
                  <p>
                    Meticulous & knowledgeable Full-Stack Developer with 4+ years of experience. Well versed with
                    MERN stack from requirement analysis, system study, designing, testing, documentation and
                    implementation with cross-technical and cross-cultural teams. Proficient in learning new 
                    technologies and quickly adapting to new environments
                  </p>
                  <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({isVisible}) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Header