import {Container} from "react-bootstrap";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <p>Copyright 2022. All Rights Reserved</p>
      </Container>
    </footer>
  )
}