import {Container, Row, Col, Tab, Nav} from "react-bootstrap";
import TrackVisibility from 'react-on-screen';

import {ProjectCard} from "./ProjectCard";
import projImg1 from "../assets/kids.png";
import projImg2 from "../assets/studio.png";
import projImg3 from "../assets/bootcamps.png";
import projImg4 from "../assets/live.png";
import projImg5 from "../assets/shipvista.png";
import projImg6 from "../assets/devswissknife.png";
import colorSharp2 from "../assets/color-sharp2.png";

const companyProjects = [
  {
    title: "ShipVista.com",
    description: "All-in-one multichannel shipping platform that offers real-time carrier rates, automated print shipping labels, and powerful tools that allow e-commerce businesses, platforms, online marketplaces, and sellers to integrate shipping with multiple carriers through our API and web application to manage their operations more efficiently.",
    imgUrl: projImg5,
    altText: ""
  },
  {
    title: "KIDS.CODE.IN",
    description: "An online coding bootcamp designed and tailored for kids to help the develop coding skills. 1:1 online sessions with whiteboard and chat integrated!",
    imgUrl: projImg1,
    altText: "kids.code.in logo"
  },
  {
    title: "STUDIO.CODE.IN",
    description: "Designed for live Coding interviews, Pair Programming, Code-along sessions or simply learn how to code with your buddies!",
    imgUrl: projImg2,
    altText: ""
  },
  {
    title: "Bootcamps.tech",
    description: "The coding bootcamp search engine to help you find that perfect bootcamp for acquiring tech skills and landing a dream job in tech industry.",
    imgUrl: projImg3,
    altText: ""
  },
  {
    title: "LIVE.CODE.IN",
    description: "45 minutes of Live Coding classes to learn and adapt new and cutting edge technologies with highly trained coding Coaches.",
    imgUrl: projImg4,
    altText: ""
  }
];

const personalProjects = [
  {
    title: "Dev Swiss Knife",
    description: "A web app with essential development tools that boost developer productivity and ease many daily tasks.",
    imgUrl: projImg6,
    altText: ""
  }
]

const Projects = () => {

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({isVisible}) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Personal Endeavours</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Company Projects</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            personalProjects.map((project, index) => (
                              <ProjectCard
                                key={index}
                                {...project} />
                            ))
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {
                            companyProjects.map((project, index) => (
                              <ProjectCard
                                key={index}
                                {...project} />
                            ))
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt='background-2'></img>
    </section>
  )
}

export default Projects