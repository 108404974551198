import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/color-sharp.png"
import nodejsLogo from "../assets/node.png"
import reactLogo from "../assets/react.png"
import reduxLogo from "../assets/redux.png"
import expressLogo from "../assets/express.svg"
import mongoDbLogo from "../assets/mongoDb.png"
import cSharpLogo from "../assets/cSharp.png"
import sqlServerLogo from "../assets/sql-server.png"


const Skills = () => {

  const skillsArr = [
    {
      image: nodejsLogo,
      className: "bg-success",
      text: 'Node JS',
    },
    {
      image: reactLogo,
      className: "bg-info",
      text: 'React JS',
    },
    {
      image: reduxLogo,
      className: "bg-purple",
      text: 'Redux JS',
    },
    {
      image: expressLogo,
      className: "bg-secondary",
      text: 'Express JS',
    },
    {
      image: mongoDbLogo,
      className: "bg-success",
      text: 'Mongo DB',
    },
    {
      image: cSharpLogo,
      className: "bg-purple",
      text: 'C#',
    },
    {
      image: sqlServerLogo,
      className: "bg-secondary",
      text: 'SQL Server',
    },
    // {
    //   image: nodejsLogo,
    //   className: "bg-info",
    //   text: 'Python',
    // },
    // {
    //   image: nodejsLogo,
    //   className: "bg-info",
    //   text: 'Visual Studio Code',
    // },
    // {
    //   image: nodejsLogo,
    //   className: "bg-info",
    //   text: 'SQL Server',
    // },
    // {
    //   image: nodejsLogo,
    //   className: "bg-info",
    //   text: 'SQL Server',
    // },
  ]

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p><br></br> </p>
              <div className="p-4 d-flex justify-content-center text-center w-100 flex-wrap">
                {skillsArr.map(s => (
                  <div className={`chip chip-lg ${s.className}`}>
                    <span className={`chip-span bg-dark-secondary rounded-circle`}>
                      <img src={s.image} alt="Contact Person" className='chip-img' />
                    </span>
                    {s.text}
                    <i className="close fas fa-times"></i>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  )
}

export default Skills