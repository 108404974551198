import 'animate.css';
import 'bootstrap/dist/css/bootstrap.css';

import './App.css';
import './Main.css';

// import blueArrows from "./assets/blue-arrows.svg"
// import kidsLogo from "./assets/kids.png"
// import studioLogo from "./assets/studio.png"
// import bootcampsLogo from "./assets/bootcamps.png"
// import liveLogo from "./assets/live.png"
import Navigation from './components/Navigation';
import Header from './components/Header';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import {Footer} from './components/Footer';

function App() {
  return (
    <>
      <Navigation />
      <Header />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
      {/* <header>
        <div className="hero-banner"></div>
        <h1 className="hero-name">Mohammed Rafath</h1>
        <div className="hero-footer">
          <h3>Full-Stack Developer & <br /> JavaScript Instructor</h3>
        </div>
      </header>
      <section id="about">
        <div className="container">
          <div className="item about-col-left">
            <h3> <img src={blueArrows} alt="" className="blue-arrows" /> ABOUT ME</h3>
            <p>Meticulous & knowledgeable Full-Stack Developer with 4+ years of experience. Well versed with
              MERN stack fromrequirement analysis, system study, designing, testing, documentation and
              implementation with cross-technical andcross-cultural
              teams. Proficient in learning new technologies andquickly adapting to new environments</p>
          </div>
          <div className="item about-col-right">
            <div className="img-me"></div>
          </div>
        </div>
      </section>
      <section id="projects">
        <h3 className="label-text ms-3">LATEST PROJECTS</h3>
        <div className="container">
          <div className="row">
            <div className="col-4 mb-5">
              <div className="card" style={{"height": "24rem;"}}>
                <img src={kidsLogo} alt="kids.code.in" id="kids-img"
                  className="card-img-top img-fluid p-3 my-3" />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://kids.code.in">KIDS.CODE.IN</a>
                  </h4>
                  <p className="card-info">
                    An online coding bootcamp designed and tailored for kids to help them
                    develop coding skills. 1:1 online sessions with whiteboard and chat integrated!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 mb-5">
              <div className="card" style={{"height": "24rem;"}}>
                <img src={studioLogo} alt="studio.code.in" id="studio-img"
                  className="card-img-top img-fluid p-3 my-3" />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://studio.code.in">STUDIO.CODE.IN</a>
                  </h4>
                  <p className="card-info">Designed for live Coding interviews, Pair Programming, Code-along
                    sessions
                    or
                    simply learn how to code with your buddies!</p>
                </div>
              </div>
            </div>
            <div className="col-4 mb-5">
              <div className="card" style={{"height": "24rem;"}}>
                <img src={liveLogo} alt="live.code.in" id="live-img"
                  className="card-img-top img-fluid p-3 my-2" />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://live.code.in">LIVE.CODE.IN</a>
                  </h4>
                  <p className="card-info">45 minutes of Live Coding classes to learn and adapt new and cutting
                    edge
                    technologies with highly trained coding Coaches.</p>
                </div>
              </div>
            </div>
            <div className="col-4 mb-5">
              <div className="card" style={{"height": "24rem;"}}>
                <img src={bootcampsLogo} alt="bootcamps" id="bootcamps"
                  className="card-img-top img-fluid p-3" />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://bootcamps.tech">BOOTCAMPS.TECH</a>
                  </h4>
                  <p className="card-info">The coding bootcamp search engine to help you find that perfect
                    bootcamp
                    for
                    acquiring tech skills and landing a dream job in tech industry.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container">
          <div className="item about-col-right">
            <div className="img-contact"></div>
          </div>
          <div className="item">
            <h3 className="contact-title"> <img src={blueArrows} alt="" className="blue-arrows" /> Contact</h3>
            <div className="contact-details">
              <h4>Email</h4>
              <p>
                <a href="mailto:mohammedrafathz@gmail.com">
                  mohammedrafathz@gmail.com
                </a>
              </p>
              <h4>Linked In</h4>
              <p>
                <a href="https://www.linkedin.com/in/mohammedrafathz/">
                  https://www.linkedin.com/in/mohammedrafathz/
                </a>
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default App;
