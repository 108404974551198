import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../assets/R.png'
import twitter from '../assets/twitter.svg'
import linkedin from '../assets/linkedin.png'
import github from '../assets/github.png'

const Navigation = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [])

  return (
    <Navbar className={scrolled ? 'scrolled' : ''} expand="lg" >
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" style={{width: '60px'}} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" >
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#home"
              className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => setActiveLink('home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => setActiveLink('skills')}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => setActiveLink('projects')}
            >
              Projects
            </Nav.Link>
          </Nav>
          <span className='navbar-text'>
            <div className="social-icon">
              <a href="https://twitter.com/mohammedrafathz" target='_blank' rel="noreferrer" >
                <img src={twitter} alt="twitter-icon" />
              </a>
              <a href="https://github.com/mohammedrafathz" target='_blank' rel="noreferrer">
                <img src={github} alt="github-icon" />
              </a>
              <a href="https://linkedin.com/in/mohammedrafathz" target='_blank' rel="noreferrer">
                <img src={linkedin} alt="linkedin-icon" />
              </a>
            </div>
            <button className='vvd' onClick={() => console.log('connect')}>
              Connect
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation